import React from "react";
import PrimarySearchAppBar from '../components/menu';
import { Container, Grid, Button, Tooltip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const useStyles = makeStyles({
    selectMounth: {
        width: '24%',
        '@media (max-width: 779px)': {
            width: '100%'
        }
    },
    selectYear: {
        width: '18%', 
        '@media (max-width: 779px)': {
            width: '100%',
            marginTop: '20px'
        },
        '@media (min-width: 780px)': {
            marginLeft: '20px'
        }
    }
});

const Sales = props => {

    const classes = useStyles();

    function createData(client, value, segment, created_at) {
        return { client, value, segment, created_at };
    }
      
    const rows = [
        createData('Patrick Alves Faciroli', 100000, 'Automóvel', '17/10/2021 às 18:13'),
        createData('Patrick Alves Faciroli', 100000, 'Automóvel', '17/10/2021 às 18:13'),
        createData('Patrick Alves Faciroli', 100000, 'Automóvel', '17/10/2021 às 18:13'),
        createData('Patrick Alves Faciroli', 100000, 'Automóvel', '17/10/2021 às 18:13'),
        createData('Patrick Alves Faciroli', 100000, 'Automóvel', '17/10/2021 às 18:13'),
        createData('Patrick Alves Faciroli', 100000, 'Automóvel', '17/10/2021 às 18:13'),
        createData('Patrick Alves Faciroli', 100000, 'Automóvel', '17/10/2021 às 18:13'),
        createData('Patrick Alves Faciroli', 100000, 'Automóvel', '17/10/2021 às 18:13')
    ];

    return (
        <>

            <PrimarySearchAppBar />

            <Container style={{ marginTop: '120px' }}>
                <Grid spacing={3} container>
                    <Grid item md={12} sx={12}>

                        <Button size="large" variant="contained" color="primary" style={{textTransform: 'none', backgroundColor: '#3062A1', color: '#FFF', fontFamily: 'PoppinsRegular', borderRadius: '8px'}}>
                            Nova venda
                        </Button>

                    </Grid>
                    <Grid item md={12} xs={12}>

                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="large" aria-label="Users">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Cliente</TableCell>
                                        <TableCell>Valor</TableCell>
                                        <TableCell>Segmento</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.client}>
                                            <TableCell component="th" scope="row">
                                                {row.client}
                                            </TableCell>
                                            <TableCell>{parseFloat(row.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                            <TableCell>{row.segment}</TableCell>
                                            <TableCell>{row.created_at}</TableCell>
                                            <TableCell>
                                                <Tooltip title="Comprovante">
                                                    <DescriptionOutlinedIcon />
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                            </Table>
                        </TableContainer>
                        
                    </Grid>
                </Grid>
            </Container>

        </>
    );

}

export default Sales;