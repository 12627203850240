import React from "react";
import PrimarySearchAppBar from '../components/menu';
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles({
    selectMounth: {
        width: '24%',
        '@media (max-width: 779px)': {
            width: '100%'
        }
    },
    selectYear: {
        width: '18%', 
        '@media (max-width: 779px)': {
            width: '100%',
            marginTop: '20px'
        },
        '@media (min-width: 780px)': {
            marginLeft: '20px'
        }
    }
});

const Simulations = props => {

    const classes = useStyles();

    function createData(name, client, value, segment, created_at) {
        return { name, client, value, segment,  created_at };
    }
      
    const rows = [
        createData('Patrick Alves Faciroli', 'Cliente Teste', 100000, 'Imóvel', '17/10/2021 às 18:13'),
        createData('Patrick Alves Faciroli', 'Cliente Teste', 100000, 'Imóvel', '17/10/2021 às 18:13'),
        createData('Patrick Alves Faciroli', 'Cliente Teste', 100000, 'Imóvel', '17/10/2021 às 18:13'),
        createData('Patrick Alves Faciroli', 'Cliente Teste', 100000, 'Imóvel', '17/10/2021 às 18:13'),
        createData('Patrick Alves Faciroli', 'Cliente Teste', 100000, 'Imóvel', '17/10/2021 às 18:13'),
        createData('Patrick Alves Faciroli', 'Cliente Teste', 100000, 'Imóvel', '17/10/2021 às 18:13'),
        createData('Patrick Alves Faciroli', 'Cliente Teste', 100000, 'Imóvel', '17/10/2021 às 18:13')
    ];

    return (
        <>

            <PrimarySearchAppBar />

            <Container style={{ marginTop: '120px' }}>
                <Grid spacing={3} container>
                    <Grid item md={12} xs={12}>

                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="large" aria-label="Users">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Usuário</TableCell>
                                        <TableCell>Cliente</TableCell>
                                        <TableCell>Valor</TableCell>
                                        <TableCell>Segmento</TableCell>
                                        <TableCell>Data</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell>{row.client}</TableCell>
                                            <TableCell>{parseFloat(row.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                            <TableCell>{row.segment}</TableCell>
                                            <TableCell>{row.created_at}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                            </Table>
                        </TableContainer>
                        
                    </Grid>
                </Grid>
            </Container>

        </>
    );

}

export default Simulations;