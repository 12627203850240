import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './App.css';
import PrimarySearchAppBar from './components/menu';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography, Button, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Doughnut, Bar } from 'react-chartjs-2';
import calculateVector from './assets/img/calculate-vector.png';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  selectMounth: {
    width: '24%',
    '@media (max-width: 779px)': {
      width: '100%'
    }
  },
  selectYear: {
    width: '18%', 
    '@media (max-width: 779px)': {
      width: '100%',
      marginTop: '20px'
    },
    '@media (min-width: 780px)': {
      marginLeft: '20px'
    }
  }
});

function App() {

  const classes = useStyles();

  const dataMeta = {
    labels: [
      'Realizado',
      'À realizar'
    ],
    datasets: [{
      data: [300, 50],
      backgroundColor: [
      '#FF6384',
      '#7AE582'
      ],
      hoverBackgroundColor: [
      '#FF6384',
      '#7AE582'
      ]
    }]
  };

  const dataMeta2 = {
    labels: [
      'Realizado',
      'À realizar'
    ],
    datasets: [{
      data: [150, 150],
      backgroundColor: [
      '#FF6384',
      '#7AE582'
      ],
      hoverBackgroundColor: [
      '#FF6384',
      '#7AE582'
      ]
    }]
  };

  const data = {
    labels: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31'
    ],
    datasets: [{
      data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310],
      backgroundColor: '#36A2EB',
      hoverBackgroundColor: '#36A2EB'
    }]
  };

  return (
    <>
      
      <PrimarySearchAppBar />

      <Container style={{ marginTop: '120px' }}>
        <Grid spacing={3} container>
          <Grid item md={9}>
        
            <Grid spacing={3} container>
              <Grid item md={12} xs={12}>
                <TextField size="large" SelectProps={{ native: true }} select variant="outlined" label="Mês" className={classes.selectMounth}>
                  <option>Janeiro</option>
                  <option>Fevereiro</option>
                  <option>Março</option>
                  <option>Abril</option>
                  <option>Maio</option>
                  <option>Junho</option>
                  <option>Julho</option>
                  <option>Agosto</option>
                  <option>Setembro</option>
                  <option>Outubro</option>
                  <option>Novembro</option>
                  <option>Dezembro</option>
                </TextField>
                <TextField size="large" SelectProps={{ native: true }} select variant="outlined" label="Ano" className={classes.selectYear}>
                  <option>2021</option>
                </TextField>
              </Grid>
              <Grid item md={4} xs={12}>
                <Paper elevation={3} style={{ padding: '24px', borderRadius: '8px' }}>
                  <Typography variant="body1" style={{fontFamily: 'PoppinsLight'}}>Vendas</Typography>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginTop: '10px'}}>
                    <Typography variant="h4" style={{fontFamily: 'PoppinsBold'}}>10</Typography>
                    <Link to="/vendas" style={{ textDecoration: 'none' }}>
                      <Typography variant="body2" style={{fontFamily: 'PoppinsLight'}}>Ver todas</Typography>
                    </Link>
                  </div>
                </Paper>
              </Grid>
              <Grid item md={4} xs={12}>
                <Paper elevation={3} style={{padding: '24px', borderRadius: '8px'}}>
                  <Typography variant="body1" style={{fontFamily: 'PoppinsLight'}}>Clientes</Typography>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginTop: '10px'}}>
                    <Typography variant="h4" style={{fontFamily: 'PoppinsBold'}}>10</Typography>
                    <Link to="/clientes" style={{ textDecoration: 'none' }}>
                      <Typography variant="body2" style={{fontFamily: 'PoppinsLight'}}>Ver todas</Typography>
                    </Link>
                  </div>
                </Paper>
              </Grid>
              <Grid item md={4} xs={12}>
                <Paper elevation={3} style={{padding: '24px', borderRadius: '8px'}}>
                  <Typography variant="body1" style={{fontFamily: 'PoppinsLight'}}>Propostas</Typography>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginTop: '10px'}}>
                    <Typography variant="h4" style={{fontFamily: 'PoppinsBold'}}>10</Typography>
                    <Link to="/propostas" style={{ textDecoration: 'none' }}>
                      <Typography variant="body2" style={{fontFamily: 'PoppinsLight'}}>Ver todas</Typography>
                    </Link>
                  </div>
                </Paper>
              </Grid>
              <Grid item md={6} xs={12}>
                <Paper elevation={3} style={{padding: '24px', borderRadius: '8px'}}>
                  <Typography variant="body1" style={{fontFamily: 'PoppinsLight'}}>Meta (Quantidade)</Typography>
                  <br/>
                  <div>
                    <Doughnut 
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        legend: {
                          display: false
                        }
                      } } data={dataMeta} height={200} />
                  </div>
                </Paper>
              </Grid>
              <Grid item md={6} xs={12}>
                <Paper elevation={3} style={{padding: '24px', borderRadius: '8px'}}>
                  <Typography variant="body1" style={{fontFamily: 'PoppinsLight'}}>Meta (Valor)</Typography>
                  <br/>
                  <div>
                    <Doughnut 
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        legend: {
                          display: false
                        }
                      } } data={dataMeta2} height={200} />
                  </div>
                </Paper>
              </Grid>
              <Grid item md={12} xs={12}>
                <Paper elevation={3} style={{padding: '24px', borderRadius: '8px'}}>
                  <Typography variant="body1" style={{fontFamily: 'PoppinsLight'}}>Volume de venda no mês</Typography>
                  <br/>
                  <div>
                    <Bar 
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        legend: {
                          display: false
                        }
                      } } data={data} height={300} />
                  </div>
                </Paper>
              </Grid>
            </Grid>

          </Grid>
          <Grid item md={3}>

            <Grid spacing={3} container>
              <Grid item md={12} xs={12}>
                <Paper elevation={3} style={{padding: '24px', borderRadius: '8px', backgroundColor: '#3062A1'}}>
                  <Typography variant="body1" style={{fontFamily: 'PoppinsLight', color: '#FFF'}}>Calculadora para consórcio</Typography>
                  <img alt="Brasil Vale" src={calculateVector} style={{ width: '100%' }} />
                  <Link to="/calculadora" style={{ textDecoration: 'none' }}>
                    <Button fullWidth size="large" variant="contained" color="primary" style={{ textTransform: 'none', backgroundColor: '#FFF', color: '#3062A1', borderRadius: '8px' }}>
                        Calcular
                    </Button>
                  </Link>
                </Paper>
              </Grid>
            </Grid>
              
          </Grid>
        </Grid>
      </Container>

    </>
  );
}

export default App;
