import React from "react";
import PrimarySearchAppBar from '../components/menu';

const Calculator = props => {

    return (
        <>

            <PrimarySearchAppBar />

        </>
    );

}

export default Calculator;