import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import logo from '../assets/img/logo-brasil-vale-192x153.jpg';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CloseIcon from '@material-ui/icons/Close';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
        display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
        width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
        display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
        display: 'none',
        },
    },
    leftItens: {
        display: 'flex',
        ['@media (max-width:699px)']: {
            display: 'none',
        }
    }
}));

const PrimarySearchAppBar = props => {

    const { handleAssessor, handleLogin } = props;
    const classes = useStyles();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const [drawer, setDrawer] = React.useState(false);

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        >
        <MenuItem>
            <IconButton aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="secondary">
                <MailIcon />
            </Badge>
            </IconButton>
            <p>Messages</p>
        </MenuItem>
        <MenuItem>
            <IconButton aria-label="show 11 new notifications" color="inherit">
            <Badge badgeContent={11} color="secondary">
                <NotificationsIcon />
            </Badge>
            </IconButton>
            <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
            <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            >
            <AccountCircle />
            </IconButton>
            <p>Profile</p>
        </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar position="fixed" style={{backgroundColor: '#FFF'}}>
                <Toolbar>
                    <Link to="/" style={{ display: 'flex' }}>
                        <img alt="Brasil Vale" src={logo} style={{ width: '70px', height: '50px', paddingTop: '20px', paddingBottom: '20px' }} />
                    </Link>
                    <div className={classes.leftItens}>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <Typography variant="body1" style={{marginLeft: '20px', color: location.pathname === "/" ? '#3062A1' : '#707072'}}>
                                Dashboard
                            </Typography>
                        </Link>
                        <Link to="/usuarios" style={{ textDecoration: 'none' }}>
                            <Typography variant="body1" style={{marginLeft: '20px', color: location.pathname === "/usuarios" ? '#3062A1' : '#707072'}}>
                                Usuários
                            </Typography>
                        </Link>
                        <Link to="/simulacoes" style={{ textDecoration: 'none' }}>
                            <Typography variant="body1" style={{marginLeft: '20px', color: location.pathname === "/simulacoes" ? '#3062A1' : '#707072'}}>
                                Simulações
                            </Typography>
                        </Link>
                        <Link to="/clientes" style={{ textDecoration: 'none' }}>
                            <Typography variant="body1" style={{marginLeft: '20px', color: location.pathname === "/clientes" ? '#3062A1' : '#707072'}}>
                                Clientes
                            </Typography>
                        </Link>
                        <Link to="/propostas" style={{ textDecoration: 'none' }}>
                            <Typography variant="body1" style={{marginLeft: '20px', color: location.pathname === "/propostas" ? '#3062A1' : '#707072'}}>
                                Propostas
                            </Typography>
                        </Link>
                        <Link to="/vendas" style={{ textDecoration: 'none' }}>
                            <Typography variant="body1" style={{marginLeft: '20px', color: location.pathname === "/vendas" ? '#3062A1' : '#707072'}}>
                                Vendas
                            </Typography>
                        </Link>
                        {/*<Typography variant="body1" style={{marginLeft: '20px', color: '#707072'}}>
                            Produtos
                        </Typography>*/}
                    </div>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <Button size="large" onClick={handleAssessor} variant="contained" color="primary" style={{textTransform: 'none', backgroundColor: '#3062A1', color: '#FFF', fontFamily: 'PoppinsRegular', borderRadius: '8px'}}>
                            Nova venda
                        </Button>
                        <Button size="large" onClick={handleAssessor} variant="contained" color="primary" style={{textTransform: 'none', backgroundColor: '#3062A1', color: '#FFF', fontFamily: 'PoppinsRegular', borderRadius: '8px', marginLeft: '10px'}}>
                            Nova proposta
                        </Button>
                        <Button size="large" onClick={handleLogin} variant="contained" color="primary" style={{textTransform: 'none', backgroundColor: '#FFF', color: '#3062A1', border: '1px solid #3062A1', marginLeft: '10px', fontFamily: 'PoppinsRegular', borderRadius: '8px'}}>
                            Sair
                        </Button>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={() => {setDrawer(true)}}
                        >
                            <MenuIcon style={{color: '#3062A1', fontSize: '30px'}} />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}

            <SwipeableDrawer
                anchor="right"
                open={drawer}
                onClose={() => {setDrawer(false);}}
                onOpen={() => {setDrawer(true)}}
            >
                <div style={{width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>

                    <CloseIcon onClick={() => {setDrawer(false);}} style={{position: 'absolute', top: '20px', right: '20px', color: '#3062A1', fontSize: '30px', cursor: 'pointer'}} />

                    <div style={{width: '90vw'}}>
                        <center>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Typography variant="body1" style={{color: location.pathname === "/" ? '#3062A1' : '#707072'}}>
                                    Dashboard
                                </Typography>
                            </Link>
                            <br></br>
                            <Link to="/usuarios" style={{ textDecoration: 'none' }}>
                                <Typography variant="body1" style={{color: location.pathname === "/usuarios" ? '#3062A1' : '#707072'}}>
                                    Usuários
                                </Typography>
                            </Link>
                            <br></br>
                            <Link to="/simulacoes" style={{ textDecoration: 'none' }}>
                                <Typography variant="body1" style={{color: location.pathname === "/simulacoes" ? '#3062A1' : '#707072'}}>
                                    Simulações
                                </Typography>
                            </Link>
                            <br></br>
                            <Link to="/clientes" style={{ textDecoration: 'none' }}>
                                <Typography variant="body1" style={{color: location.pathname === "/clientes" ? '#3062A1' : '#707072'}}>
                                    Clientes
                                </Typography>
                            </Link>
                            <br></br>
                            <Link to="/propostas" style={{ textDecoration: 'none' }}>
                                <Typography variant="body1" style={{color: location.pathname === "/propostas" ? '#3062A1' : '#707072'}}>
                                    Propostas
                                </Typography>
                            </Link>
                            <br></br>
                            <Link to="/vendas" style={{ textDecoration: 'none' }}>
                                <Typography variant="body1" style={{color: location.pathname === "/vendas" ? '#3062A1' : '#707072'}}>
                                    Vendas
                                </Typography>
                            </Link>
                            <br></br>
                            <Button size="large" onClick={handleAssessor} variant="contained" color="primary" style={{textTransform: 'none', backgroundColor: '#3062A1', color: '#FFF', width: '70%', borderRadius: '8px'}}>
                                Nova venda
                            </Button>
                            <br></br>
                            <br></br>
                            <Button size="large" onClick={handleAssessor} variant="contained" color="primary" style={{textTransform: 'none', backgroundColor: '#3062A1', color: '#FFF', width: '70%', borderRadius: '8px'}}>
                                Nova proposta
                            </Button>
                            <br></br>
                            <br></br>
                            <Button size="large" onClick={handleLogin} variant="contained" color="primary" style={{textTransform: 'none', border: '1px solid #3062A1', color: '#3062A1', backgroundColor: '#FFF', width: '70%', borderRadius: '8px'}}>
                                Sair
                            </Button>
                        </center>
                    </div>

                </div>
            </SwipeableDrawer>

        </div>
    );
}

export default PrimarySearchAppBar;