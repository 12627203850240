import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Clients from './pages/clients';
import Calculator from './pages/calculator';
import Users from './pages/users';
import Proposals from './pages/proposals';
import Sales from './pages/sales';
import Simulations from './pages/simulations';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={App} />
      <Route path="/clientes" component={Clients} />
      <Route path="/calculadora" component={Calculator} />
      <Route path="/usuarios" component={Users} />
      <Route path="/propostas" component={Proposals} />
      <Route path="/vendas" component={Sales} />
      <Route path="/simulacoes" component={Simulations} />
      <Route path="/*" component={App} />
    </Switch>
  </ BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
